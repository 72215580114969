
import React from 'react';
import { Link } from 'gatsby'

import styles from './products.module.scss';

import Layout from '../../components/layout';
import CallToAction from '../../components/cta';
import WayWeWork from '../../components/www';

import { qa, diploma, rolled } from '../../images/icons';
import { teaching_team } from '../../images/team';

export default () => (
  <Layout>
    <div className={styles.productPage}>
      <div className={styles.topSection}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>College Counseling</div>
          <div className={styles.description}>Our program helps students devise a successful application strategy by helping them find their best-fit schools, showcase their unique qualities, and tell a memorable story.</div>
        </div>
      </div>
      <div className={styles.iconSection}>
        <div className={styles.card}>
          <img src={qa} />
          <div className={styles.meta}>Individualized Guidance</div>
          {/*<div className={styles.description}>Every student that joins our SAT program receives a customized plan for their preparation that is based on data. Using this cutting-edge analytical approach, we can learn the precise concepts each student struggles with on the exam. This allows us to provide customized and dynamic guidance to each student about how they can better improve upon their weaknesses.</div>*/}
          <div className={styles.description}>Meet 1-on-1 with an expert consultant to receive tailored guidance on school selection, interview preparation, and college essay writing. We use a team-based approach, so each student in our program benefits from the expertise of each of our counselors.</div>
        </div>
        <div className={styles.card}>
          <img src={diploma} />
          <div className={styles.meta}>Incredible Outcomes</div>
          {/*<div className={styles.description}>Students who enroll in at least 60 of hours of our SAT preparation program can expect to gain up to 200 points on their score.  This improvement is based on our curriculum, which emphasizes familiarity with the exam; just like any sport, mastering the SAT is all about PRACTICE--so in each of our lesson formats, we administer regular mock exams and tailored review sessions.</div>*/}
          <div className={styles.description}>Our results speak for themselves: 20% of Blueprint students have received an offer from a top 10 national university or liberal arts college; 88% of Blueprint students received an offer from a top 30 national university or liberal arts college.</div>
        </div>
        <div className={styles.card}>
          <img src={rolled} />
          <div className={styles.meta}>Real Expertise</div>
          {/*<div className={styles.description}>In addition to degrees from world class universities, all our instructors scored in the 99th percentile on both the SAT & ACT and are subject-matter experts in the specific subjects they each. Most importantly, they are teachers at heart and are heavily invested in the improvement of each individual student. Learn more about our amazing team here.</div>*/}
          <div className={styles.description}>Our Shanghai-based team and global network of mentors includes former admissions officers from some of the top colleges in the US. College admissions is always changing, and our team of real experts is best positioned to support with a families long-term planning. <Link to='/team/main'>Meet our team here.</Link></div>
        </div>
      </div>
      <WayWeWork />
      <CallToAction text='Want to learn more about how our counseling programs can help you achieve your goals?' cta='Schedule a Free Consultation'/>
      <div className={styles.formatSection}>
        <div className={styles.header}>Key Program Features</div>
        <div className={styles.description}>Our admissions consulting program provides 1-on-1 guidance for up to 12 college applications. It begins during the summer before a student’s senior year and lasts for the next 12 months until the student has committed to a college. Meetings occur weekly.</div>
        <div className={styles.description}>Here are some more key details about the program’s curriculum:</div>
          <div className={styles.row}>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>School Selection</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Create a preliminary list of 15-20 colleges or universities</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Conduct rigorous, data-driven research on each school to determine fit</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Connect with alumni and current students of each school</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Narrow the preliminary list down to a final list of best-fit schools</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>College Essay Writing</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Develop effective narrative skills with Blueprint’s proprietary storytelling curriculum</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Build a compelling “hook” that showcases your strengths and helps you stand out</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Complete a final draft of the personal statement before senior year begins</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Receive end-to-end support on all college essays right up until final deadlines</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={[styles.meta, styles.bold].join(' ')}>Additional Services</div>
              </div>
              <div className={styles.cardBody}>
                <ul className={styles.cardList}>
                  <li className={styles.cardItem}>
                    <p>Receive comprehensive interview preparation with structured feedback</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Ensure key deadlines are met with effective application time management</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Learn how to effectively show “demonstrated interest” in your top schools</p>
                  </li>
                  <li className={styles.cardItem}>
                    <p>Get advice on how to transition to life in college and secure internships</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  </Layout>
)